/* ----------------- desktop ----------------- */

.test {
  display: flex;
  align-items: stretch;
  min-height: 100vh;
}

.test__error-span {
  position: fixed;
  top: 5%;
  left: 70%;

  display: flex;
  justify-content: center;
  min-width: 250px;
  width: 20%;
  padding: 15px;

  color: #fff;
  font-weight: bold;

  background-color: red;
}

.test__section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 95vw;
}

.test__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 75px;

  border-bottom: 2px solid $color-soft;
}

.test__logo-image {
  width: calc(200px + (250 - 200) * ((100vw - 1024px) / (1640 - 1024)));
  height: auto;
}

.test__menu-button {
  display: none;
  width: 45px;
  height: 45px;

  font-size: 0;

  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    fill: $color-head;
  }
}

.test__main-container {
  position: relative;

  display: flex;
  min-height: 600px;
  align-items: center;
  flex-grow: 1;
  padding: 58px 120px;

  z-index: 1;
  background-color: #FFFAFB;

  &--welcome {
    display: block;

    h2 {
      margin: 10px 0;
      margin-top: 0;

      font-size: 32px;
      text-align: center;
      color: $color-head;
    }

    p {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;

      font-family: inherit;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: $color-head;
    }
  }

  &--review {
    display: block;
    padding-top: 70px;

    h2 {
      color: $color-head;
    }
  }

  .loading {
    position: absolute;
    left: 50%;
    top: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 91%;
    padding-top: 50px;

    background-color: #FFFAFB;
    transform: translate(-50%, -45%);
    z-index: 2;
  }
}

.test__pre-question-container {
  text-align: left;
  color:#000000;
  margin-top:10px;

  & > p {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    font-family: inherit;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    color: #64186d;
  }
}

.test__loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}

.test__progress-box {
  position: absolute;
  top: -60px;
  left: 50%;

  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: -30px;

  background: #ffffff;
  border-radius: 50%;

  transform: translateX(-50%);
}

.test__linear-progress {
  display: none;
}

.test__progress {
  width: 120px;
  height: 120px;
}

.test__form {
  width: 100%;
  margin: 0;
  padding: 0;
}

.test__fieldset {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  height: 100%;
  margin: 0;
  padding: 0;

  border: none;

  &--pre {
    justify-content: space-around;
    margin-bottom: 80px;

    label {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--rev {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.test__question-container {
  max-height: 440px;
  width: 65%;
  margin-right: 8%;

  text-align: left;
  color: rgba(0, 0, 0, 0);

  overflow-y: scroll;
  transition: $default-transition-settings;

  &:hover {
    color: rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
  }
}

.test__fieldset-intro {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;

  font-size: 18px;
  color: #000000;
  text-align: left;

  transition: 1s ease all;

  &--old {
    color: $color-soft;
  }
}

.test__fieldset-question {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;

  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  text-align: left;
  color: $color-head;
  
  transition: 1s ease all;

  &--old {
    color: $color-soft;
    font-weight: normal;
  }
}

.test__fieldset-alert {
  display: flex;
  width: 100%;
  margin: 1rem 0;

  font-size: 18px;
  line-height: 22px;

  color: $color-orange;
  transition: 1s ease all;
}

.test__fieldset-question-animation {
  opacity: 0;
}

.test__fieldset-question-animation-enter {
  opacity: 0;
}

.test__fieldset-question-animation-enter-active {
  opacity: 1;
  transition: all 1s ease;
}

.test__fieldset-question-animation-exit-active {
  opacity: 0;
  transition: all 1s ease;
}

.test__fieldset-question-animation-exit-done {
  opacity: 0;
}

.test__answers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.test__radiobutton {
  display: none;

  &:checked + label {
    color: #ffffff;
    background-color: $color-green;
    border-color: $color-green;

    &:active {
      box-shadow: none;
    }

    span {
      &:first-child { 
        &::after {
          background-color: #ffffff;
        }
      }
    }
  }
}

.test__radio-label {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  min-width: 215px;
  margin-bottom: 20px;
  padding: 15px 20px;

  border: 2px solid $color-soft;
  background-color: #ffffff;

  cursor: pointer;
  transition: $default-transition-settings;

  &--review {
    margin-right: 3rem;
    max-width: 400px;
  }

  &:hover {
    border-color:$color-orange;

    span {
      &:first-child { 
        &::after {
          background-color: $color-orange;
        }
      }
    }
  }

  &:active {
    box-shadow: 3px 3px 5px $color-orange;
  }

  span {
    &:first-child {
      position: relative;

      margin-right: 15px;
      padding-right: 15px;

      &::after {
        content: "";

        position: absolute;
        right: 0;
        top: 50%;

        display: block;
        width: 2px;
        height: 200%;
        background-color: $color-soft;

        transform: translateY(-50%);
        transition: $default-transition-settings;
      }
    }
  }
}

// ----- Aside-block

.test__aside {
  flex-grow: 2;
  max-width: 100px;
  padding: 20px 10px;

  background: $gradient-aside;

  transition: $default-transition-settings;
}

.test__close-button {
  position: relative;

  display: none;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: 1rem;
  margin-bottom: 1rem;

  font-size: 0;

  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  &::after {
    content: "X";

    position: absolute;
    left: 50%;
    top: 50%;

    display: block;
    width: 100%;
    height: 60%;

    font-size: 24px;
    font-weight: bold;
    color: #ffffff;

    transform: translate(-50%, -50%);
  }
}

.test__aside-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.test__aside-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  font-size: 18px;
  font-weight: bold;
  color: #ffffff;

  transition: $default-transition-settings;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
}

.test__aside-svg {
  width: 55px;
  height: 55px;

  fill: #ffffff;
}

.test__aside-overflow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  display: none;
  width: 100vw;

  background-color: $color-soft;
  opacity: 0.5;

  z-index: 1;

  &--open {
    display: block;
  }
}

// ----- Switch-buttons

.test__switch-button {
  position: absolute;
  top: 50%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100px;
  margin: 0;
  padding: 0;

  font-size: 0;

  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  transform: translateY(-50%);

  &:hover {
    .test__switch-svg {   
      fill: gray;
    }
  }

  &:active {
    .test__switch-svg {   
      fill: #000000;
    }
  }

  &:disabled {
    opacity: 0.2;

    pointer-events: none;
  }

  &--prev {
    left: 30px;
  }

  &--next {
    right: 30px;
  }
}

.test__switch-svg {
  width: 100%;
  height: auto;

  fill: $color-soft;
  transition: $default-transition-settings;
}

// ----- Page footer

.test__footer {
  height: 120px;

  border-top: 2px solid $color-soft;
}

// ----- Resume component

.test__resume-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  h2 {
    width: 100%;
    margin: 0;
    margin-bottom: 35px;

    color: $color-head;
  }
}

.test__aware-description {
  margin-bottom: 1rem;
}

.test__resume-answers-container {
  width: 100%;
  max-height: 400px;
  margin-bottom: 35px;

  color: rgba(0, 0, 0, 0);

  overflow-y: scroll;
  transition: $default-transition-settings;

  &:hover {
    color: rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
  }
}

.test__answers-list {
  margin: 0;
  padding: 0;
  
  list-style: none;
}

.test__answer-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;

  background-color: #ffffff;

  &:last-child {
    margin-bottom: 0;
  }
}

.test__question-number {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 2rem;
  
  font-size: 20px;
  font-weight: 500;
  color: $color-bright;

  border: 2px solid $color-button-add;
  border-radius: 50%;
}

.test__question-text {
  margin: 0;
  padding: 0;

  font-size: 18px;
  font-weight: 500;
  color: $color-head;
}

.test__question-comment {
  display: block;
  width: 100%;
  margin: 0;
  margin-top: 1rem;
  padding: 0;

  font-size: 18px;
  font-weight: 500;
  color: $color-font;

  span {
    margin-right: 1rem;

    font-size: 18px;
    font-weight: 500;
    color: $color-head; 
  }
}

.test__question-answer {
  display: block;
  width: 100%;
  margin: 0;
  margin-top: 1rem;
  padding: 0;

  font-size: 18px;
  font-weight: 500;
  color: $color-green;

  span {
    margin-right: 1rem;

    font-size: 18px;
    font-weight: 500;
    color: $color-head; 
  }

  &--empty {
    color: red;
  }
}

.test__resume-button {
  width: 300px;
  margin: 0 auto;
}

// Pre-question form

.test__pre-form {
  width: 100%;

  h3 {
    margin: 0;
    padding: 0;

    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: $color-head;
  }

  p {
    margin: 35px 0;

    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
}

.test__checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0;  
}

.test__checkbox-label {
  display: flex;
  align-items: center;

  cursor: pointer;

  span {
    transition: 0.3s ease-in-out all;
  }
  
  &:hover {
    span {
      color: $color-head;
    }

    .svg-checkbox__box {
      stroke: $color-head;
    }
  }

  &:active {
    span {
      opacity: 0.9;
    }
  }
}

.test__checkbox {
  pointer-events: none;

  &:focus + label {
    span {
      color: $color-head;
    }

    .svg-checkbox__box {
      stroke: $color-head;
    }
  }

  &:checked + label {
    .svg-checkbox__box {
      fill:#66FF66;
      stroke-dashoffset: 320;
    }
  
    .svg-checkbox__check {
      stroke-dashoffset: 0;
    }
  }
}

.test__checkbox-svg {
  width: 50px;
  height: 50px;
  margin-right: 10px;

  .svg-checkbox__box {
    fill: #ffffff;
    stroke: $color-head;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 320;
    stroke-dashoffset: 0;

    transition-delay: 0s, 0s, 0.4s;
    transition-property: stroke, stroke-dashoffset, fill;
    transition-duration: 0.3s, 0.45s, 0.4s;
  }

  .svg-checkbox__check {
    fill: none;
    stroke: $color-head;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 80;
    stroke-dashoffset: 80;

    transition: stroke-dashoffset 0.3s linear;
  }
}

.test__pre-button {
  width: 300px;
  margin: 0 auto;
}

// Special classes for review page

.test__user-answer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;

  background-color: #ffffff;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    display: block;
    width: 100%;

    &:first-child {
      margin-bottom: 1rem;

      font-weight: 700;
      color: $color-head;
    }

    &:nth-child(2) {
      color: #FF8C00;
    }
  }

  &--review {
    align-items: center;
    width: 100%;

    label {
      font-weight: bold;
      margin-right: 1rem;
    }

    input, select {
      min-width: 180px;
      width: 40%;
      height: 30px;
      padding-left: 1rem;
      
      border: 2px solid $color-soft;

      outline: none;

      &:hover,
      &:focus {
        border-color: $color-bright;
      }

      &:valid:not(:placeholder-shown) {
        border-color: $color-green;
      }

      transition: $default-transition-settings;
    }

    button {
      height: 30px;

      color: #ffffff;

      background-color: $color-green;
      border: none;
      outline: none;
      cursor: pointer;

      transition: $default-transition-settings;

      &:hover {
        background-color: $color-bright;
      }

      &:disabled {
        background-color: $color-soft;
      }
    }
  }
}

.test__aware {
  display: block;
  margin: 0;

  font-size: 22px;
  font-weight: bold;
  color: $color-head;
}

.test__aware-checkbox {
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

.test__aware-button {
  margin: 0 auto;
}

// Error classes

.test__error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  span {
    display: block;
    margin-bottom: 2rem;

    font-size: 32px;
    font-weight: bold;
    color: $color-head;
  }
}

.test__error-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 260px;
}

/* ----------------- tablet ----------------- */

@media (max-width: ($viewport--lg - 1)) {
  .test {
    position: relative;
  }

  .test__section {
    width: 100%;
  }

  .test__main-container {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    flex-wrap: wrap;
  
    padding: 45px 80px;

    &--review {
      & > h2 {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }

  .test__progress-box {
    display: none;
  }

  .test__linear-progress {
    display: block;
    width: 100%;
    margin-bottom: 1rem;

    border-bottom: 2px solid $color-soft;

    span {
      display: block;
      margin: 1rem 0;

      font-size: 18px;
      font-weight: bold;

      color: $color-head;
    }
  }

  .test__form {
    padding: 0;
  }

  .test__fieldset {
    flex-wrap: wrap;

    &--pre {
      justify-content: space-around;
      margin-bottom: 30px;

      label {
        margin-right: 0;
      }
    }
  }

  .test__pre-form {
    h3 {
      font-size: 24px;
    }

    p {
      font-size: 20px;
    }
  }

  .test__question-container {
    width: 100%;
    max-height: inherit;
    padding: 0;
    margin: 0;
    margin-bottom: 40px;

    overflow: inherit;
  }

  .test__answers-container {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    flex-grow: 0;
    margin: 0 auto;
    align-items: center;
  }

  .test__radio-label {  
    &--review {
      margin-right: 0;
      max-width: inherit;
    } 
  }

  .test__switch-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .test__switch-button {
    position: relative;
  
    width: 150px;
    height: auto;
    padding: 15px;
    margin: 0;

    font-size: 16px;
    font-weight: 700;
    color: #ffffff;

    background-color: $color-button-add;
    border-radius: 5px;
    
    transform: translateY(0);

    svg {
      display: none;
    }

    &--prev {
      left: 0;
    }
  
    &--next {
      right: 0;
    }
  }

  // ----- Page footer

  .test__footer {
    display: none;
  }

  // ----- Resume component

  .test__resume-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    h2 {
      width: 100%;
      margin: 0;
      margin-bottom: 35px;

      color: $color-head;
    }
  }

  .test__resume-answers-container {
    max-height: inherit;
    margin-bottom:  20px;
    padding: 0;

    overflow: inherit;
  }

  .test__resume-button {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  // ----- Aside-block

  .test__aside {
    width: 60px;
  }
}

/* -----------------  mobile ----------------- */

@media (max-width: ($viewport--md - 1)) {
  .test__section {
    display: flex;
  }

  .test__header {
    padding: 15px;
  }

  .test__menu-button {
    display: block;
  }

  .test__main-container {
    flex-grow: 1;
    padding: 25px 15px;

    &--welcome {
      h2 {
        margin-bottom: 20px;

        font-size: 22px;
      }

      p {
        font-size: 16px;
      }
    }

    &--review {
      h2 {
        display: none;
      }

      .test__linear-progress {
        order: 0;
      }
    }
  }

  .test__checkbox-container {
    margin: 20px 0;
  }

  .test__question-container {
    margin-bottom: 15px;
  }

  .test__fieldset-intro { 
    font-size: 16px;
  }

  .test__fieldset-question {
    font-size: 14px;
  }

  .test__switch-button {
    width: 120px;
    padding: 10px 5px;

    font-size: 14px;
  }

  // ----- Resume component

  .test__resume-container {
    h2 {
      margin-bottom: 20px;

      font-size: 20px;
    }
  }

  .test__question-text,
  .test__question-answer {
    font-size: 14px;

    span {
      font-size: 14px
    }
  }

  // ----- Aside-block

  .test__aside {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    max-width: inherit;
    width: 0;
    padding: 15px 0;

    z-index: 2;

    &--open {
      width: 80%;

      .test__aside-list {
        display: flex;
      }

      .test__close-button {
        display: block;
      }
    }
  }

  .test__aside-list {
    display: none;
  }

  .test__aside-item {
    width: 100%;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .test__aside-link {
    justify-content: flex-start;
    align-items: stretch;
    padding: 5px 15px;

    span {
      position: relative;

      display: flex;
      flex-grow: 1;
      align-items: center;
      padding-left: 35px;
      margin-left: 15px;

      &::after {
        content: "";

        position: absolute;
        left: 0;
        top: 50%;

        display: block;
        width: 2px;
        height: 80%;
        background-color: #ffffff;

        transform: translateY(-50%);
      }
    }

    &:hover {
      background-color: $color-bright;
    }
  }

  // Special classes for review page

  .test__resume-answers-container {
    order: 1;
    margin-bottom: 1rem;
  }

  .test__aware {
    order: 1;
    font-size: 18px;
  }

  .test__aware-description {
    order: 1;
  }

  .test__form {
    order: 1;
  }

  .test__switch-container {
    margin-top: auto;
    order: 2;
  }

  .test__resume-button {
    order: 1;
  }
}
