.choice-state-modal {
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;

  &-content {
    display: flex;
    flex-direction: column; /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center; /* center items horizontally, in this case */

    height: 100%;
    padding: 20px 40px;
    margin: 10px auto;
    border-radius: 10px;
    max-width: 700px;
    max-height: 500px;
    background: linear-gradient(148deg, #794097, #3d204c);

    &-item {
      color: $color-white;
      margin: 0 0 20px 0;

      .heading {
        color: $color-white;
      }

      .success {
        color: $color-green;
      }

      .errors {
        color: $color-error;
      }

      select {
        padding: 10px 20px;
      }

      button {
        padding: 10px 20px;
        border-radius: 5px;
        color: $color-font;
        background: $color-white;
      }
    }
  }

  /* Full-width input fields */
}